import { Suspense } from 'react'

import SideNav from 'components/layout/SideNav/SideNav'
import useMainLayout from 'hooks/common/layout/useMainLayout'

const SideNavRenderer = () => {
  const { sideNavMini, sideNavOpen, toggleSideNav, toggleSideNavMini, isReady } = useMainLayout()

  if (!isReady) return null

  return (
    <Suspense fallback={null}>
      <SideNav
        open={sideNavOpen}
        onClose={toggleSideNav}
        mini={sideNavMini}
        toggleSideNavMini={toggleSideNavMini}
      />
    </Suspense>
  )
}

export default SideNavRenderer
