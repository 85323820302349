import { Divider, ListItem, ListItemButton, ListItemText, Menu } from '@mui/material'

import Link from 'next/link'

import { useTranslations } from 'next-intl'

import styles from 'components/users/UserProfileMenu.module.scss'
import usePermissions from 'hooks/permissions/usePermissions'
import useLogout from 'hooks/useLogout'
import useSlots from 'hooks/useSlots'

import Routes from 'types/enums/routes'

import { ET_HELP_CENTER_URL } from 'utils/constants/global'

import type { ReactNode } from 'react'

interface UserProfileMenuProps {
  anchorEl: HTMLElement | null
  doClose: () => void
  open: boolean
  children: ReactNode
}

const UserProfileMenu = ({ anchorEl, doClose, open, children }: UserProfileMenuProps) => {
  const t = useTranslations()

  const slots = useSlots({ children })

  const { hasRoutePermissions } = usePermissions()

  const { logout } = useLogout()

  return (
    <Menu
      open={open}
      onClose={doClose}
      anchorEl={anchorEl}
      disableAutoFocusItem
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      classes={{ paper: styles.menuPaper }}>
      <ListItem dense sx={{ px: 1 }}>
        {slots['global-context-picker']}
      </ListItem>
      <ListItem disableGutters disablePadding>
        <ListItemButton href={Routes.SETTINGS_USER_PROFILE} LinkComponent={Link}>
          <ListItemText primary={t('profile')} />
        </ListItemButton>
      </ListItem>
      {hasRoutePermissions(Routes.SETTINGS_SUBSCRIPTION) && (
        <ListItem disableGutters disablePadding>
          <ListItemButton href={Routes.SETTINGS_SUBSCRIPTION} LinkComponent={Link}>
            <ListItemText primary={t('subscription')} />
          </ListItemButton>
        </ListItem>
      )}
      <ListItem disableGutters disablePadding>
        <ListItemButton href={ET_HELP_CENTER_URL} target="_blank" LinkComponent={Link}>
          <ListItemText primary={t('learningCenter')} />
        </ListItemButton>
      </ListItem>
      {slots['mobile-search']}
      <Divider />
      <ListItem disableGutters disablePadding>
        <ListItemButton className={styles.logout} onClick={() => logout()}>
          <ListItemText primary={t('logout')} />
        </ListItemButton>
      </ListItem>
    </Menu>
  )
}

export default UserProfileMenu
