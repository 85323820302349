import { ExitToApp } from '@mui/icons-material'
import { Button, IconButton, Tooltip } from '@mui/material'

import { useTranslations } from 'next-intl'

import useLegacyAppUrl from 'hooks/useLegacyAppUrl'
import useScreenSizes from 'hooks/useScreenSizes'

const BackToClassicButton = () => {
  const t = useTranslations()
  const { isSmallScreen } = useScreenSizes()
  const legacyAppUrl = useLegacyAppUrl()

  if (isSmallScreen) {
    return (
      <Tooltip title={t('backToClassic')}>
        <IconButton size="small" component="a" href={legacyAppUrl}>
          <ExitToApp sx={{ rotate: '180deg' }} />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <Button href={legacyAppUrl} variant="soft" startIcon={<ExitToApp sx={{ rotate: '180deg' }} />}>
      {t('backToClassic')}
    </Button>
  )
}

export default BackToClassicButton
