import { Box } from '@mui/material'

import { Loader } from '@eventtemple/eventtemple-ui'

const FullPageLoader = () => (
  <Box
    flex="1"
    width="100%"
    display="flex"
    minHeight="300px"
    height="100%"
    alignItems="center"
    justifyContent="center">
    <Loader />
  </Box>
)

export default FullPageLoader
