import { Suspense } from 'react'

import TopNav from 'components/layout/TopNav/TopNav'
import useMainLayout from 'hooks/common/layout/useMainLayout'
import useRouteRedirection from 'hooks/common/layout/useRouteRedirection'

const TopNavRenderer = () => {
  const { sideNavMini, toggleSideNav, isReady } = useMainLayout()

  useRouteRedirection()

  if (!isReady) return null

  return (
    <Suspense fallback={null}>
      <TopNav sideNavMini={sideNavMini} toggleSideNav={toggleSideNav} />
    </Suspense>
  )
}

export default TopNavRenderer
