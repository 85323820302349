import { Suspense, useCallback, useMemo } from 'react'

import { Box } from '@mui/material'

import { ErrorBoundary } from '@sentry/nextjs'

import ErrorFallback from 'components/common/ErrorBoundary/ErrorFallback'
import FullPageLoader from 'components/FullPageLoader'

import useMainLayout from 'hooks/common/layout/useMainLayout'
import useErrorBoundary from 'hooks/useErrorBoundary'

import { SIDE_NAV, TOP_NAV } from 'utils/constants/layout'

import PreviewAppAlert from './PreviewAppAlert'

export const mainContainerStyles = (isReady: boolean, lgUp: boolean, sideNavMini: boolean) => ({
  position: 'relative',
  height: '100%',
  margin: '0',
  display: 'flex',
  flexDirection: 'column',
  ...(isReady && {
    width: lgUp ? `calc(100% - ${sideNavMini ? SIDE_NAV.W_MINI : SIDE_NAV.W_VERTICAL}px)` : '100%',
    marginLeft: lgUp ? `${sideNavMini ? SIDE_NAV.W_MINI : SIDE_NAV.W_VERTICAL}px` : 0,
    paddingTop: `${TOP_NAV.H_DESKTOP}px`
  })
})

interface ErrorFallbackProps {
  resetError: () => void
}

interface MainLayoutContentProps {
  children: React.ReactNode
}

const MainLayoutContent = ({ children }: MainLayoutContentProps) => {
  const { childrenBoundaryRef } = useErrorBoundary()
  const { sideNavMini, lgUp, isReady } = useMainLayout()

  const containerStyles = useMemo(
    () => mainContainerStyles(isReady, lgUp, sideNavMini),
    [isReady, lgUp, sideNavMini]
  )

  const errorBoundaryFallback = useCallback(
    ({ resetError }: ErrorFallbackProps) => {
      childrenBoundaryRef.current = resetError
      return <ErrorFallback resetError={resetError} />
    },
    [childrenBoundaryRef]
  )

  return (
    <Box sx={containerStyles}>
      <Suspense fallback={<FullPageLoader />}>
        <PreviewAppAlert />

        <ErrorBoundary fallback={errorBoundaryFallback}>{children}</ErrorBoundary>
      </Suspense>
    </Box>
  )
}

export default MainLayoutContent
