import { useMemo } from 'react'

import useChainContextStore from 'store/chainContext'
import { LEGACY_APP_URL } from 'utils/constants/global'

const useLegacyAppUrl = () => {
  const selectedOrganization = useChainContextStore((state) => state.selectedOrganization)

  const url = useMemo(
    () =>
      LEGACY_APP_URL +
      (selectedOrganization?.id
        ? `?et_org_subdomain=${selectedOrganization.subdomain}`
        : '/chain/bookings'),
    [selectedOrganization]
  )

  return url
}

export default useLegacyAppUrl
