import { usePathname } from 'next/navigation'

import Custom500 from 'components/500'
import FullPageLoader from 'components/FullPageLoader'
import useCurrentUser from 'hooks/currentUser/useCurrentUser'

import UiProvider from 'providers/UiProvider'

import useCurrentUserStore from 'store/currentUser'

import Routes from 'types/enums/routes'

import type { AxiosError } from 'axios'
import type { ReactNode } from 'react'

type UserProviderProps = {
  children: ReactNode
}

/**
 * The UserProvider is a wrapper component that loads the current user when the
 * application mounts. If there is no user logged in, it will redirect them to
 * the login page. This only needs to be invoked at the top level of the
 * application. Any components that need the current user data can use the
 * useCurrentUserStore.
 *
 * @component
 */
const UserProvider = ({ children }: UserProviderProps): React.JSX.Element => {
  const pathname = usePathname()
  const { isLoading, error } = useCurrentUser()

  const status = (error as AxiosError)?.response?.status

  const getLoginUrl = useCurrentUserStore((state) => state.getLoginUrl)

  if (isLoading) return <FullPageLoader />

  if (status === 401) {
    if (pathname !== Routes.AUTH_LOGIN) window.location.href = getLoginUrl(window.location.href)
  } else if (error && status != 200) {
    console.log('error', error)

    return (
      <UiProvider>
        <Custom500 />
      </UiProvider>
    )
  }

  return <>{children}</>
}

export default UserProvider
