'use client'

import { memo } from 'react'

import { Box } from '@mui/material'

import BaseLayout from 'components/layout/BaseLayout'
import SideNavRenderer from 'components/layout/MainLayout/SideNavRenderer'
import TopNavRenderer from 'components/layout/MainLayout/TopNavRenderer'
import MainAppProviders from 'providers/MainAppProviders'

import MainLayoutContent from './MainLayout/MainLayoutContent'

interface MainLayoutProps {
  children: React.ReactNode
}

const MainLayout = ({ children }: MainLayoutProps) => (
  <BaseLayout>
    <MainAppProviders>
      <TopNavRenderer />
      <Box height="100%">
        <SideNavRenderer />
        <MainLayoutContent>{children}</MainLayoutContent>
      </Box>
    </MainAppProviders>
  </BaseLayout>
)

export default memo(MainLayout)
