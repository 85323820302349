import { Campaign } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'

import { useTranslations } from 'next-intl'

import { CannyChangelog } from 'react-canny'

const renderCannyComponent = () => null

const ProductUpdate = () => {
  const t = useTranslations()

  return (
    <>
      <Tooltip title={t('productUpdates')} placement="bottom" arrow>
        <IconButton data-canny-changelog>
          <Campaign />
        </IconButton>
      </Tooltip>
      <CannyChangelog position="bottom" align="right" component={renderCannyComponent} />
    </>
  )
}

export default ProductUpdate
