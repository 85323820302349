import { useMemo } from 'react'

import { type Breakpoint } from '@mui/material/styles'

import { themeBreakpoints } from '@repo/theme'
import { useMediaQuery } from 'usehooks-ts'

export type Query = 'up' | 'down' | 'between' | 'only'
export type Value = Breakpoint | number

const useResponsive = (query: Query, start?: Value, end?: Value): boolean => {
  const mediaQuery = useMemo(() => {
    switch (query) {
      case 'up':
        return themeBreakpoints.up(start as Value)
      case 'down':
        return themeBreakpoints.down(start as Value)
      case 'between':
        return themeBreakpoints.between(start as Value, end as Value)
      case 'only':
        return themeBreakpoints.only(start as Breakpoint)
      default:
        throw new Error(`Invalid query type: ${query}`)
    }
  }, [end, query, start])

  /* Use usehooks-ts media query instead of mui's useMediaQuery  to avoid re-rendering issues */
  const res = useMediaQuery(mediaQuery.replace('@media ', ''))

  return res
}

export default useResponsive
