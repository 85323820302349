import { CorporateFare } from '@mui/icons-material'
import { Avatar } from '@mui/material'

import type { AvatarProps } from '@mui/material'

const MultiOrgIcon = (props: AvatarProps) => (
  <Avatar {...props}>
    <CorporateFare color="action" fontSize="inherit" />
  </Avatar>
)

export default MultiOrgIcon
