import { useCallback, useMemo } from 'react'

import { useMutation } from '@tanstack/react-query'

import useNavigation from 'hooks/common/useNavigation'
import useResponsive from 'hooks/useResponsive'
import useUserSettingResource from 'hooks/userSettings/useUserSettingsResource'
import useScreenSizes from 'hooks/useScreenSizes'
import useChainContextStore from 'store/chainContext'
import useCurrentUserStore from 'store/currentUser'
import useMainLayoutStore from 'store/mainLayoutStore'

import type { UserSetting } from '@repo/et-types'

const useMainLayout = () => {
  const { isLoading, isAuthenticated, userSetting, updateCurrentUser } = useCurrentUserStore(
    (state) => ({
      isAuthenticated: state.isAuthenticated,
      isLoading: state.isLoading,
      userSetting: state.user_setting,
      updateCurrentUser: state.updateCurrentUser
    })
  )

  const { selectedChain, selectedOrganization } = useChainContextStore((state) => ({
    selectedChain: state.selectedChain,
    selectedOrganization: state.selectedOrganization
  }))

  const { isSmallScreen } = useScreenSizes()
  const userSettingResource = useUserSettingResource()
  const { isSettingNav } = useNavigation()

  const { sideNavOpen, sideNavMini, toggleSideNav, setSideNavMini } = useMainLayoutStore()

  const { mutateAsync: sideBarStateMutation } = useMutation(async (sideBarFolded: boolean) => {
    userSettingResource.update({ id: userSetting?.id, sidebar_folded: sideBarFolded })
    return updateCurrentUser({
      user_setting: { ...userSetting, sidebar_folded: sideBarFolded } as UserSetting
    })
  })

  const isReady = useMemo<boolean>(
    () => Boolean(!isLoading && isAuthenticated && Boolean(selectedChain || selectedOrganization)),
    [isLoading, isAuthenticated, selectedChain, selectedOrganization]
  )

  const toggleSideNavMini = useCallback(async () => {
    if (!isSmallScreen) await sideBarStateMutation(!sideNavMini)

    setSideNavMini(!sideNavMini)
  }, [isSmallScreen, sideBarStateMutation, sideNavMini, setSideNavMini])

  const lgUp = useResponsive('up', 'lg')

  const computedSideNavMini = isSettingNav || !lgUp ? false : sideNavMini

  return {
    sideNavOpen,
    sideNavMini: computedSideNavMini,
    toggleSideNav,
    toggleSideNavMini,
    lgUp,
    isReady
  }
}

export default useMainLayout
