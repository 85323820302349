import { type RefObject, memo } from 'react'

import { ArrowDropDown } from '@mui/icons-material'
import { Avatar, Box, Typography } from '@mui/material'

import { Clickable } from '@eventtemple/eventtemple-ui'
import { useTranslations } from 'next-intl'

import useResponsive from 'hooks/useResponsive'
import useChainContextStore from 'store/chainContext'
import useCurrentUserStore from 'store/currentUser'

type TopNavProfileButtonProps = {
  buttonRef: RefObject<HTMLButtonElement | null>
  onClick: () => void
  open: boolean
}

const TopNavProfileButton = ({ buttonRef, onClick, open }: TopNavProfileButtonProps) => {
  const t = useTranslations()

  const [name, avatarUrl, avatarThumbUrl] = useCurrentUserStore((state) => [
    state.name,
    state.logo_url,
    state.logo_thumb_url
  ])

  const selectedOrganization = useChainContextStore((state) => state.selectedOrganization)

  const lgUp = useResponsive('up', 'lg')

  return (
    <Box ref={buttonRef}>
      <Clickable onClick={onClick}>
        {lgUp && (
          <Box mr={1}>
            <Typography variant="body2" width="100%" noWrap textAlign="right">
              {name}
            </Typography>
            <Typography variant="body2" width="100%" textAlign="right" fontWeight="semiBold" noWrap>
              {selectedOrganization ? selectedOrganization.name : t('chain')}
            </Typography>
          </Box>
        )}

        <Avatar src={avatarThumbUrl || avatarUrl || undefined} alt={t('altText.userAvatar')} />

        <ArrowDropDown
          color="action"
          sx={{
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: (theme) =>
              theme.transitions.create('transform', { duration: theme.transitions.duration.short })
          }}
        />
      </Clickable>
    </Box>
  )
}

export default memo(TopNavProfileButton)
