import { Grid2 as Grid, Typography } from '@mui/material'

import { useTranslations } from 'next-intl'

import CustomErrorRenderer from 'components/common/CustomErrorRenderer'
import { StaticAssets } from 'types/enums/staticAssets'

const Custom500 = () => {
  const t = useTranslations()

  return (
    <CustomErrorRenderer>
      <Grid container direction="column" textAlign="center" rowSpacing={1} size={12}>
        <Grid>
          <Typography variant="h3">{t('serverError.title')}</Typography>
        </Grid>
        <Grid>
          <Typography variant="body1" color="text.secondary">
            {t('serverError.description')}
          </Typography>
        </Grid>
      </Grid>
      <Grid display="block" width="90%">
        <img src={StaticAssets.SERVER_ERROR} alt="server-error" />
      </Grid>
    </CustomErrorRenderer>
  )
}

export default Custom500
