import { themeBreakpoints } from '@repo/theme'

import { useMediaQuery } from 'usehooks-ts'

type UseScreenSizesReturnValue = {
  isMdScreen: boolean
  isSmallScreen: boolean
  isXSSmallScreen: boolean
  isLgScreen: boolean
}

const useScreenSizes = (): UseScreenSizesReturnValue => {
  /* Use usehooks-ts media query instead of mui's useMediaQuery  to avoid re-rendering issues */
  const isXSSmallScreen = useMediaQuery(themeBreakpoints.only('xs').replace('@media ', ''))
  const isSmallScreen = useMediaQuery(themeBreakpoints.down('sm').replace('@media ', ''))
  const isMdScreen = useMediaQuery(themeBreakpoints.down('md').replace('@media ', ''))
  const isLgScreen = useMediaQuery(themeBreakpoints.up('lg').replace('@media ', ''))

  return {
    isXSSmallScreen,
    isSmallScreen,
    isMdScreen,
    isLgScreen
  }
}

export default useScreenSizes
