import { useCallback, useMemo } from 'react'

import {
  Apartment,
  Bookmark,
  CalendarMonth,
  Dashboard,
  DriveFileMove,
  HourglassTop,
  Insights,
  Mail,
  Person,
  Settings,
  Task,
  Textsms
} from '@mui/icons-material'

import { useRouter } from 'next/navigation'

import { useTranslations } from 'next-intl'

import usePermissions from 'hooks/permissions/usePermissions'
import useStats from 'hooks/useStats'
import useChainContextStore from 'store/chainContext'
import Routes from 'types/enums/routes'

import type { SideNavItem } from 'hooks/common/layout/sideNav/useSideNav'

const useMainSideNavItems = (): SideNavItem[] => {
  const t = useTranslations()
  const router = useRouter()

  const { selectedChain, selectedOrganization } = useChainContextStore((state) => ({
    selectedChain: state.selectedChain,
    selectedOrganization: state.selectedOrganization
  }))

  const { hasRoutePermissions } = usePermissions()

  const { userStats } = useStats()

  const shouldHideItem = useCallback(
    (route: Routes) => {
      if (selectedChain || selectedOrganization) return !hasRoutePermissions(route)

      return false
    },
    [selectedChain, selectedOrganization, hasRoutePermissions]
  )

  const items = useMemo<SideNavItem[]>(
    () => [
      {
        label: t('dashboard'),
        icon: <Dashboard />,
        href: Routes.DASHBOARD,
        hidden: shouldHideItem(Routes.DASHBOARD)
      },
      {
        label: t('calendars'),
        icon: <CalendarMonth />,
        variant: 'dropdown',
        onClick: () => router.push(Routes.BOOKING_CALENDAR),
        subItems: [
          {
            label: t('bookingCalendar'),
            href: Routes.BOOKING_CALENDAR,
            hidden: shouldHideItem(Routes.BOOKING_CALENDAR)
          },
          {
            label: t('eventDiary'),
            href: Routes.EVENT_DIARY,
            hidden: shouldHideItem(Routes.EVENT_DIARY)
          },
          {
            label: t('grc'),
            href: Routes.GRC,
            hidden: shouldHideItem(Routes.GRC)
          },
          {
            label: t('taskCalendar'),
            href: Routes.TASK_CALENDAR,
            hidden: shouldHideItem(Routes.TASK_CALENDAR)
          }
        ]
      },
      {
        label: 'Global RFPs',
        icon: <DriveFileMove />,
        href: Routes.RFPS,
        hidden: shouldHideItem(Routes.RFPS)
      },
      {
        label: 'RFPs',
        icon: <DriveFileMove />,
        href: Routes.ORGANIZATION_RFPS,
        hidden: shouldHideItem(Routes.ORGANIZATION_RFPS)
      },
      {
        label: t('bookings'),
        onClick: () => router.push(Routes.BOOKINGS),
        icon: <Bookmark />,
        variant: selectedChain?.id ? 'default' : 'dropdown',
        hidden: shouldHideItem(Routes.BOOKINGS),
        subItems: selectedChain?.id
          ? undefined
          : [
              {
                label: t('bookings'),
                href: Routes.BOOKINGS,
                hidden: shouldHideItem(Routes.BOOKINGS)
              },
              {
                label: t('events'),
                href: Routes.EVENTS,
                hidden: shouldHideItem(Routes.EVENTS)
              },
              {
                label: t('groups'),
                href: Routes.GROUPS,
                hidden: shouldHideItem(Routes.GROUPS)
              },
              {
                label: t('documents'),
                hidden: shouldHideItem(Routes.DOCUMENTS),
                href: Routes.DOCUMENTS
              },
              {
                label: t('proposals'),
                href: Routes.PROPOSALS,
                badgeLabel: t('beta'),
                hidden: shouldHideItem(Routes.PROPOSALS)
              },
              {
                label: t('invoices'),
                href: Routes.INVOICES,
                hidden: shouldHideItem(Routes.INVOICES)
              }
            ]
      },
      {
        label: t('contacts'),
        icon: <Person />,
        href: Routes.CONTACTS,
        hidden: shouldHideItem(Routes.CONTACTS)
      },
      {
        label: t('accounts'),
        icon: <Apartment />,
        href: Routes.ACCOUNTS,
        hidden: shouldHideItem(Routes.ACCOUNTS)
      },
      {
        label: t('tasks'),
        icon: <Task />,
        href: Routes.TASKS,
        badgeLabel: userStats?.overdue_task_count,
        hidden: shouldHideItem(Routes.TASKS)
      },
      {
        label: t('mail'),
        icon: <Mail />,
        href: Routes.MAIL,
        badgeLabel: userStats?.unread_email_count,
        hidden: shouldHideItem(Routes.MAIL)
      },

      {
        label: t('textMessages'),
        icon: <Textsms />,
        href: Routes.TEXT_MESSAGES,
        badgeLabel: userStats?.unread_incoming_text_count,
        hidden: shouldHideItem(Routes.TEXT_MESSAGES)
      },
      {
        label: t('automations'),
        icon: <HourglassTop />,
        href: Routes.AUTOMATIONS,
        badgeLabel: userStats?.active_automation_count,
        hidden: shouldHideItem(Routes.AUTOMATIONS)
      },
      {
        label: t('reports'),
        icon: <Insights />,
        href: Routes.REPORTS
      },
      {
        label: t('settings'),
        icon: <Settings />,
        href: Routes.SETTINGS,
        hidden: shouldHideItem(Routes.SETTINGS)
      }
    ],
    [t, userStats, selectedChain, shouldHideItem, router]
  )

  return items
}

export default useMainSideNavItems
