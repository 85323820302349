import { Alert, Grid2 as Grid, Link, Typography } from '@mui/material'

import { useTranslations } from 'next-intl'

const criticalErrorsLink =
  'https://intercom.help/eventtemple/en/articles/5715073-what-does-your-account-has-critical-errors-mean'

const EmailErrorAlert = ({ errorMessage }: { errorMessage?: string | null }) => {
  const t = useTranslations()

  return (
    <Alert severity="error">
      <Grid container direction="column" rowSpacing={2}>
        <Grid>
          <Typography color="inherit">{t('weReceivedAnError')}</Typography>
        </Grid>
        <Grid>
          <Typography color="inherit" fontWeight={500}>
            {errorMessage || t('unknownError')}
          </Typography>
        </Grid>
        <Grid>
          <Typography color="inherit">
            {`${t('pleaseTryDisconnecting')} `}
            <Link rel="noreferrer" target="_blank" href={criticalErrorsLink}>
              {t('learnMore')}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Alert>
  )
}

export default EmailErrorAlert
