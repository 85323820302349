'use client'

import MainLayout from 'components/layout/MainLayout'
import withAuthenticationRequired from 'hocs/withAuthenticationRequired'

import type { ReactNode } from 'react'

type LayoutProps = { children: ReactNode }

// Wrap all pages with authentication HOC. This ensures no pages within
// the main layout are accessible without authentication.
const AuthenticatedPage = withAuthenticationRequired(
  ({ children }: { children: ReactNode }) => children
)

const RootMainLayout = ({ children }: LayoutProps) => (
  <MainLayout>
    <AuthenticatedPage>{children}</AuthenticatedPage>
  </MainLayout>
)

export default RootMainLayout
