import { create } from 'zustand'

type MainLayoutState = {
  sideNavOpen: boolean
  sideNavMini: boolean
  toggleSideNav: () => void
  setSideNavMini: (value: boolean) => void
}

const useMainLayoutStore = create<MainLayoutState>((set) => ({
  sideNavOpen: false,
  sideNavMini: false,
  toggleSideNav: () => set((state) => ({ sideNavOpen: !state.sideNavOpen })),
  setSideNavMini: (value: boolean) => set(() => ({ sideNavMini: value }))
}))

export default useMainLayoutStore
