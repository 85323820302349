'use client'

import { memo, useCallback, useEffect } from 'react'

import { omit } from 'lodash'

import FullPageLoader from 'components/FullPageLoader'
import useCurrentUserStore from 'store/currentUser'

import type { FunctionComponent, ReactNode } from 'react'

// The keys that define a component in React. We want to only copy any extra
// properties from the original component to the WrappedComponent and exclude
// these keys.
const primaryComponentKeys = ['<prototype>', 'name', 'length']

const withAuthenticationRequired = (Component: FunctionComponent<{ children: ReactNode }>) => {
  const WrappedComponent = (props: { children: ReactNode }): React.JSX.Element => {
    const { getLoginUrl, isAuthenticated, isLoading } = useCurrentUserStore((state) => ({
      isAuthenticated: state.isAuthenticated,
      isLoading: state.isLoading,
      getLoginUrl: state.getLoginUrl
    }))

    const redirectToLogin = useCallback(() => {
      window.location.href = getLoginUrl(window.location.href)
    }, [getLoginUrl])

    useEffect(() => {
      if (!isAuthenticated && !isLoading) {
        redirectToLogin()
      }
    }, [isAuthenticated, isLoading, redirectToLogin])

    if (isLoading) return <FullPageLoader />

    return <Component {...props} />
  }

  return memo(Object.assign(WrappedComponent, omit(Component, primaryComponentKeys)))
}

export default withAuthenticationRequired
