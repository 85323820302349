import { cloneDeep, get as lodashGet } from 'lodash'

import Routes from 'types/enums/routes'

import type { Task } from '@repo/et-types'
import type { DrawerCloseReason } from 'types/drawer'

export const capitalizeFirstLetter = (str?: string) => {
  if (!str) return ''

  return str?.[0]?.toUpperCase() + str.slice(1)
}

export const handleDrawerClose =
  (closeDrawer: () => void, allowBackdropClick?: boolean) =>
  (_event: unknown, reason: DrawerCloseReason) => {
    if (reason == 'escapeKeyDown') {
      closeDrawer()
    }

    if (allowBackdropClick && reason == 'backdropClick') {
      closeDrawer()
    }
  }

/**
 function to filter an array of objects based on a key and value.
 Returns a copy of filtered array keeping the structure of the original array.
@function
@param(array, key, value)
@returns(array)
*/
/* eslint-disable @typescript-eslint/no-explicit-any */
export const deepFilter = (
  array: any[],
  filterKey: string,
  filterValue: string | boolean | number
) => {
  const reduceFunction = (result: any, object: any) => {
    const value = lodashGet(object, filterKey)
    const keyDoesntExist = value === undefined
    const containsFilterValue = value?.toString().toLowerCase().includes(filterValue.toString())

    const containsOrIsEqual =
      typeof filterValue === 'string' ? containsFilterValue : value === filterValue

    if (Array.isArray(object.children)) {
      const children = deepFilter(object.children, filterKey, filterValue)

      if (children.length) {
        result.push(Object.assign(object, { children }))
      }
    } else if (keyDoesntExist || containsOrIsEqual) {
      result.push(object)
    }

    return result
  }

  return cloneDeep(array).reduce(reduceFunction, [])
}

/**
 * Returns the base path for a given path.
 * @param {Route} path - The path to get the base path for.
 * @returns {Route} - The base path.
 * @example
 * getBasePath('/accounts/123') -> '/accounts'
 * getBasePath('/accounts/123/edit') -> '/accounts'
 * getBasePath('/settings') -> '/settings'
 *
 */
export const getBasePath = (path: Routes): Routes => {
  // paths that already are the base path
  if (path === Routes.ROOT || path === Routes.SETTINGS) return path

  const basePath = Object.values(Routes)
    // sort by length descending to get the longest path first
    // eg: "/settings/setup_values/account_types" should match Routes.SETTINGS_ACCOUNT_TYPES before matching Routes.SETTINGS_SETUP_VALUES or Routes.SETTINGS
    ?.sort((a, b) => b.length - a.length)
    ?.find((route) => path?.startsWith(route))
  const isSettingsPage = path?.startsWith(Routes.SETTINGS)

  return basePath ?? (isSettingsPage ? Routes.SETTINGS : Routes.ROOT)
}

interface GetReorderedListParams<T> {
  list: T[]
  startIndex: number
  endIndex: number
}

/**
 * Returns a reordered list based on the start and end index.
 * @param {T[]} list - The list to reorder.
 * @param {number} startIndex - The index of the item to move.
 * @param {number} endIndex - The index to move the item to.
 * @returns {T[]} - The reordered list.
 */
export const getReorderedList = <T>({
  list,
  startIndex,
  endIndex
}: GetReorderedListParams<T>): T[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)

  result.splice(endIndex, 0, removed)

  return result
}

/**
 * @param {Task} task - The task to check.
 * @returns {boolean} - A boolean indicating if the task is overdue.
 */
export const isTaskOverdue = (task: Task) => {
  let isOverdue = false

  if (task.due_at) {
    const dueAt = task.all_day ? new Date(task.due_at).toDateString() : new Date(task.due_at)

    if (dueAt < new Date()) {
      isOverdue = true
    }
  }

  return isOverdue && !task.is_completed
}

/**
 * @param {number|string} value The number to format.
 * @param {number} decimalPlaces The number of decimal places to format the number to.
 * @returns {string} The formatted number as a string
 */
export const formatNumberToStr = (value: number | string, decimalPlaces?: number) => {
  const num = Number(value)

  if (isNaN(num)) return value

  return Number.isInteger(num) ? num.toString() : num.toFixed(decimalPlaces ?? 2)
}
