'use client'

import { memo, useCallback, useMemo, useRef } from 'react'

import { Menu } from '@mui/icons-material'
import { AppBar, Grid2 as Grid, IconButton, Paper, Toolbar } from '@mui/material'

import { useToggle } from 'usehooks-ts'

import GlobalSearch from 'components/common/GlobalSearch/GlobalSearch'
import Slot from 'components/common/Slot'
import BackToClassicButton from 'components/layout/TopNav/BackToClassicButton'
import EmailErrorPopover from 'components/layout/TopNav/EmailErrorPopover'
import ProductUpdate from 'components/layout/TopNav/ProductUpdate'
import TopNavProfileButton from 'components/layout/TopNav/TopNavProfileButton'

import GlobalContextPicker from 'components/pickers/GlobalContextPicker'
import UserProfileMenu from 'components/users/UserProfileMenu'
import useResponsive from 'hooks/useResponsive'

import useCurrentUserStore from 'store/currentUser'
import { SIDE_NAV, TOP_NAV } from 'utils/constants/layout'

type TopNavProps = {
  toggleSideNav: () => void
  sideNavMini?: boolean
}

const titleContainerStyles = {
  visibility: { lg: 'visible', xs: 'hidden' },
  display: { xs: 'none', lg: 'flex' }
}

const TopNav = ({ toggleSideNav, sideNavMini }: TopNavProps) => {
  const lgUp = useResponsive('up', 'lg')

  const buttonRef = useRef<HTMLButtonElement>(null)

  const [userMenuOpen, toggleUserMenu] = useToggle(false)
  const [, toggleSearch] = useToggle(false)

  const [chainAdmin, chain, emailSettings, currentUserOrganizations] = useCurrentUserStore(
    (state) => [state.chain_admin, state.chain, state.email_setting, state.organizations]
  )

  const showGlobalContextPicker = useMemo(
    () =>
      chainAdmin || (currentUserOrganizations.length > 0 && (chain?.organizations_count ?? 0) > 1),
    [chainAdmin, chain, currentUserOrganizations]
  )

  const appBarStyles = useCallback(
    () => ({
      height: TOP_NAV.H_DESKTOP,
      top: 0,
      transform: {
        xs: 'none',
        lg: `translateX(${sideNavMini ? SIDE_NAV.W_MINI : SIDE_NAV.W_VERTICAL}px)`
      }
    }),
    [sideNavMini]
  )

  const toolBarStyles = useMemo(
    () => ({
      height: TOP_NAV.H_DESKTOP,
      width: {
        xs: '100%',
        lg: `calc(100% - ${sideNavMini ? SIDE_NAV.W_MINI : SIDE_NAV.W_VERTICAL}px)`
      }
    }),
    [sideNavMini]
  )

  const emailProviderError = emailSettings?.errored || emailSettings?.error_message

  const menuButton = useMemo(() => {
    if (lgUp) return null

    return (
      <Grid>
        <IconButton onClick={toggleSideNav}>
          <Menu />
        </IconButton>
      </Grid>
    )
  }, [lgUp, toggleSideNav])

  const productUpdates = useMemo(() => {
    if (!lgUp) return null

    return (
      <Grid>
        <ProductUpdate />
      </Grid>
    )
  }, [lgUp])

  const backToClassicButton = useMemo(() => {
    if (!lgUp) return null

    return (
      <Grid>
        <BackToClassicButton />
      </Grid>
    )
  }, [lgUp])

  const emailProviderErrorPopover = useMemo(() => {
    if (!emailProviderError) return null

    return (
      <Grid>
        <EmailErrorPopover emailSettings={emailSettings} />
      </Grid>
    )
  }, [emailProviderError, emailSettings])

  const globalContextPickerSlot = useMemo(() => {
    if (!showGlobalContextPicker) return null

    return (
      <Slot name="global-context-picker">
        <GlobalContextPicker onSelect={toggleUserMenu} />
      </Slot>
    )
  }, [showGlobalContextPicker, toggleUserMenu])

  const searchMaxWidth = { xs: 'unset', lg: '20rem' }

  return (
    <>
      <AppBar position="fixed" sx={appBarStyles}>
        <Toolbar square component={Paper} sx={toolBarStyles}>
          <Grid container alignItems="center" flexWrap="nowrap" columnSpacing={2} width="100%">
            {menuButton}
            {/*
              Using display here because the top-nav-page-title element always needs to be present in dom.
              This is because the PageTitle component uses createPortal to render the title in the top nav.
            */}
            <Grid sx={titleContainerStyles}>
              <div id="top-nav-page-title" />
            </Grid>
            <Grid flex="1" display="flex" flexWrap="nowrap">
              <Grid
                container
                flexWrap="nowrap"
                columnSpacing={2}
                flex="1"
                display="flex"
                alignItems="center"
                justifyContent="flex-end">
                <Grid flex="1" maxWidth={searchMaxWidth}>
                  <GlobalSearch toggleSearch={toggleSearch} />
                </Grid>
                {backToClassicButton}
                {productUpdates}
                {emailProviderErrorPopover}
                <Grid>
                  <TopNavProfileButton
                    buttonRef={buttonRef}
                    onClick={toggleUserMenu}
                    open={userMenuOpen}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <UserProfileMenu open={userMenuOpen} doClose={toggleUserMenu} anchorEl={buttonRef.current}>
        {globalContextPickerSlot}
      </UserProfileMenu>
    </>
  )
}

export default memo(TopNav)
