import { useEffect, useMemo } from 'react'

import { IntercomProvider as IntercomProviderWrapper, useIntercom } from 'react-use-intercom'

import useChainContextStore from 'store/chainContext'
import useCurrentUserStore from 'store/currentUser'

import type { ReactNode } from 'react'

import type { IntercomProps } from 'react-use-intercom'

type IntercomProviderProps = {
  children: ReactNode
}

const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_VERCEL_ENV
  ? process.env.NEXT_PUBLIC_INTERCOM_APP_ID
  : ''

const IntercomBooter = ({ children }: IntercomProviderProps): React.JSX.Element => {
  const currentOrg = useChainContextStore((state) => state.selectedOrganization)
  const currentUser = useCurrentUserStore()
  const { boot, shutdown } = useIntercom()

  const bootProps = useMemo(() => {
    const defaultProps: IntercomProps = {
      userId: String(currentUser.id),
      name: currentUser.name, // Full name
      email: currentUser.email || undefined, // Email address
      phone: currentUser.phone_number || undefined, // Phone number
      createdAt: currentUser.created_at, // Signup date as a Unix timestamp
      customAttributes: {
        // Custom attributes are searchable in Intercom
        chain_admin: currentUser.chain_admin
      }
    }

    if (currentOrg) {
      defaultProps['company'] = {
        companyId: String(currentOrg.id ?? ''),
        name: currentOrg.name,
        industry: currentOrg.vendor_type,
        website: currentOrg.website,
        size: currentOrg.employee_count || 0,
        createdAt: currentOrg.created_at
      }
    }

    return defaultProps
  }, [currentOrg, currentUser])

  useEffect(() => {
    if (!INTERCOM_APP_ID) return

    boot(bootProps)
    return () => shutdown()
  }, [boot, bootProps, shutdown])

  return <>{children}</>
}

const IntercomProvider = ({ children }: IntercomProviderProps): React.JSX.Element => {
  const currentUser = useCurrentUserStore()

  const shouldInitializeIntercom = useMemo(
    () => Boolean(!currentUser.isETAdmin && INTERCOM_APP_ID),
    [currentUser.isETAdmin]
  )

  if (!INTERCOM_APP_ID) return <>{children}</>

  return (
    <IntercomProviderWrapper appId={INTERCOM_APP_ID} shouldInitialize={shouldInitializeIntercom}>
      <IntercomBooter>{children}</IntercomBooter>
    </IntercomProviderWrapper>
  )
}

export default IntercomProvider
