import { useTranslations } from 'next-intl'

import { useSnackbar } from 'notistack'

import type { ApiError } from '@repo/et-types/common/api'
import type { AxiosError } from 'axios'
import type { OptionsObject, SnackbarOrigin } from 'notistack'

export type ErrorData = {
  errors: ApiError[]
}

type ShowSuccessConfig = {
  message?: string
} & OptionsObject

type ShowErrorConfig = {
  error?: AxiosError
  message?: string
} & OptionsObject

const defaultAnchorOrigin: SnackbarOrigin = { vertical: 'top', horizontal: 'center' }

type UseApiSnackBar = {
  showError: (config: ShowErrorConfig) => void
  showSuccess: (config: ShowSuccessConfig) => void
}

const useApiSnackBar = (): UseApiSnackBar => {
  const t = useTranslations()

  const { enqueueSnackbar } = useSnackbar()

  const showError = ({ error, message, ...options }: ShowErrorConfig) => {
    const errorData = error?.response?.data as ErrorData

    let errorMessage = message || t('defaultError')

    if (errorData?.errors) {
      const errors = errorData?.errors

      errorMessage = errors.reduce((res, error, index) => {
        if (error.source?.pointer) {
          const source = /data\/attributes\/(.*)/.exec(error.source.pointer)

          if (source) res += `${source[1]}: `
        }

        res += error.detail

        if (index < errors.length - 1) res += ', '

        return res
      }, '')
    }

    return enqueueSnackbar(errorMessage, {
      variant: 'error',
      anchorOrigin: defaultAnchorOrigin,
      ...options
    })
  }

  const showSuccess = ({ message, ...options }: ShowSuccessConfig) =>
    enqueueSnackbar(message, {
      variant: 'success',
      anchorOrigin: defaultAnchorOrigin,
      ...options
    })

  return { showError, showSuccess }
}

export default useApiSnackBar
