import { useCallback } from 'react'

import { DateFormat, DateTimeFormat, TimeFormat } from '@repo/utils/datetime'
import { isSameDay } from 'date-fns'

import useDateTime from 'hooks/common/dateTime/useDateTime'

type getFormattedEventDateTimeStrParams = {
  startAt: Date
  endAt: Date
  allDay: boolean
  options?: {
    dateFormat?: DateFormat
    dateTimeFormat?: DateTimeFormat
    timeFormat?: TimeFormat
  }
}

const useFormattedDateTimeStr = () => {
  const { getFormattedDate, getFormattedTime, getFormattedDateRange, getFormattedDateTimeRange } =
    useDateTime()

  const getFormattedEventDateTimeStr = useCallback(
    ({ startAt, endAt, allDay, options }: getFormattedEventDateTimeStrParams) => {
      const areDatesTheSame = isSameDay(startAt, endAt)
      const startDate = getFormattedDate({
        date: startAt,
        dateFormat: options?.dateFormat ?? DateFormat.EEE_MMM_d_yyyy
      })

      if (allDay) {
        return areDatesTheSame
          ? `${startDate}`
          : getFormattedDateRange({
              startDate: startAt,
              endDate: endAt,
              dateFormat: options?.dateFormat ?? DateFormat.EEE_MMM_d_yyyy
            })
      }

      const startTime = getFormattedTime({
        date: startAt,
        timeFormat: options?.timeFormat ?? TimeFormat.h_mm_a
      })
      const endTime = getFormattedTime({
        date: endAt,
        timeFormat: options?.timeFormat ?? TimeFormat.h_mm_a
      })

      return areDatesTheSame
        ? `${startDate} ${startTime} - ${endTime}`
        : getFormattedDateTimeRange({
            startDate: startAt,
            endDate: endAt,
            dateTimeFormat: options?.dateTimeFormat ?? DateTimeFormat.EEE_MMM_d_yyyy_h_mm_a
          })
    },
    [getFormattedDate, getFormattedDateRange, getFormattedDateTimeRange, getFormattedTime]
  )

  return { getFormattedEventDateTimeStr }
}

export default useFormattedDateTimeStr
