import { useEffect } from 'react'

import { usePathname, useRouter } from 'next/navigation'

import usePermissions from 'hooks/permissions/usePermissions'
import Routes from 'types/enums/routes'

const useRouteRedirection = () => {
  const router = useRouter()

  const { hasRoutePermissions } = usePermissions()
  const currentPath = usePathname()

  useEffect(() => {
    if (hasRoutePermissions(currentPath as Routes)) return

    const isSettingsPage = currentPath?.startsWith(Routes.SETTINGS)

    let redirect = Routes.SETTINGS

    if (hasRoutePermissions(Routes.BOOKINGS)) {
      redirect = Routes.BOOKINGS
    }

    if (isSettingsPage) {
      redirect = Routes.SETTINGS
      if (hasRoutePermissions(Routes.SETTINGS_ORGANIZATIONS)) {
        redirect = Routes.SETTINGS_ORGANIZATIONS
      } else if (hasRoutePermissions(Routes.SETTINGS_GENERAL_SETTINGS)) {
        redirect = Routes.SETTINGS_GENERAL_SETTINGS
      }
    }

    router.push(redirect)
  }, [currentPath, hasRoutePermissions, router])
}

export default useRouteRedirection
