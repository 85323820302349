import { Box, Grid2 as Grid } from '@mui/material'

import type { ReactNode } from 'react'

const CustomErrorRenderer = ({ children }: { children: ReactNode }) => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
    <Box display="flex" flexWrap="wrap" justifyContent="center" width="600px">
      <Grid container rowSpacing={6} justifyContent="center">
        {children}
      </Grid>
    </Box>
  </Box>
)

export default CustomErrorRenderer
